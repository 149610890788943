
import { FilterMixin } from '@/mixins/filterMixin';
import filter from '@/store/modules/filter';
import { Component, Mixins } from 'vue-property-decorator'

@Component
export default class ProductCatalog extends Mixins(FilterMixin) {
  get categories() {
    return filter.categories;
  }

  async created() {
    await filter.fetchFilters()
  }
}
